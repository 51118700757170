import { Router } from "@reach/router";
import React from "react";
import LoginHeader from "../../components/login-header";
import LoginForm from "./components/log-in-form";

const Login = () => {
  return (
    <div className="signup-container">
      <LoginHeader />
      <Router basepath="/login" className="login-body">
        <LoginForm path="/*" />
      </Router>
    </div>
  );
};

export default Login;
